/* font family
	============================================================================================ */
$title-font: 'Josefin Sans', sans-serif;
$text-font: 'Open Sans', sans-serif;


/* color variables
	============================================================================================ */
$title-color: #2f2d4e;
$text-color: #888888;
$primary-color: #e22104;
$link-color: #777777;

$white: #fff;
// $black: #222222;
// $soapstone : #fff9f7;


/* google fonts
	============================================================================================ */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:400,600,700');

