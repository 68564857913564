  
.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}
.abs{
    position: absolute;
}
.activeText{
    opacity: 1;
    color:black;
}
.inactiveText{
    opacity: 0.4;
    color:black;
}
.stepButton{
    width: 90px;
    height:30px;
    border-radius: 7px;
    padding:5px;
    text-align: center;
    cursor: pointer;
    background:green
}

.nextStep{
    float: right;
}
.prevStep{
    float: left;
}
.sandwichOption{
    margin: 10px;
    opacity: 0.4;
    padding: 5px;
    border-radius: 3px;
    text-align: center;
    float:left;
    border: 1px solid transparent;
}
.sandwichOption:hover{
    border: 1px black solid;
    cursor: pointer;
}
.sandwichOptionSelected{
    opacity: 1;
    border: 1px black;
    border-style: solid;
    cursor: pointer;
    margin: 10px;
    padding: 5px;
    border-radius: 3px;
    text-align: center;
    float:left;
}

/* Quiddel */
/* https://codepen.io/art-lamark/pen/qZpoBz?editors=0100 */

.NextPrevButton {
    width: 120px;
    height: 30px;
	border: none;
	background: none;
	color: inherit;
	vertical-align: middle;
	position: relative;
    z-index: 1;
    text-align: center;
    -moz-osx-font-smoothing: grayscale;
    opacity: 0.2;
    cursor: default;
}

.NextPrevButton--quidel {
	overflow: hidden;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
	-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.green {
	background:#21bf73;
	color: #21bf73;
}
.red {
	background:#fd5e53;
	color: #fd5e53;
}
.black{
    background: black;
    color: black;
    height: 40px;
    width: 140px;
}
.NextPrevButton--quidel::before,
.NextPrevButton--quidel::after {
	content: '';
	position: absolute;
	z-index: -1;
	border-radius: inherit;
}
.NextPrevButton--quidel::after {
	top: 1px;
	left: 1px;
	right: 1px;
	bottom: 1px;
	background: #fff;
}
.NextPrevButton--quidel.NextButton--inverted::after {
	background: white;
}
.NextButton--quidel::before .PrevButton--quidel::before{
	background: white;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.Next--quidel.Next--inverted::before {
	background: #21bf73;
}
.Prev--quidel.Prev--inverted::before {
	background: #fd5e53;
}

.NextPrevButton--round-s.NextPrevButton--quidel::after {
	border-radius: 5px;
}
.NextPrevButton--round-m.NextPrevButton--quidel::after {
	border-radius: 5px;
}
.NextPrevButton--round-l.NextPrevButton--quidel::after {
	border-radius: 5px;
}
.NextPrevButton--quidel > span {
	padding-left: 0.35em;
}
.Next--quidel.Next--inverted {
    opacity: 1;
    cursor: pointer;
}
.Prev--quidel.Prev--inverted {
    opacity: 1;
    cursor: pointer;
}

/* End */
.margin10{
    margin: 10px;
    border: none;
}
.receipt{
    float: right;
    border: 1px solid black;
    width: 30%;
    height: 100%;
}
.OrderForm{
    width: 45%;
    float:right;
    position: absolute;
    margin-left: 600px;
}

@media only screen and (max-width: 1200px) {
    .OrderForm{
        width: 100%;
        position: static;
        margin-left: 0px;
    }
}

.addItemOption{
    cursor: pointer;
    /* float: right; */
    border: 1px solid blue;
    padding: 5px;
    color: blue;
    text-align: center;
    display: block;
    /* margin: 10px 70px 10px 70px; */
}
.itemOptions{
    width: 100%;
    padding: 0px 40px 0px 40px;
    min-height: 100px;
}
.NameInput{
    border: none;
    width: 50%;
    height: 2em;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-bottom: 5px;
}
html {
    scroll-behavior: smooth;
  }
.scrollLink{
    height: 30px;
    display: block;
    width: 100%
}
.orderFormContainer{
    margin-top: 1em;
    height: 800px;
} 