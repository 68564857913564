//--------- start footer Area -------------//
.footer-area {
	padding: 120px 0px 120px;
	// background: url(/images/footer-bg.png) no-repeat center;
	background: #FFF8F7;
	@media (max-width: 991px) {
		// background: $title-color;
		padding: 60px 0px;
	}
	.single-footer-widget {
		@media (max-width: 991px) {
			margin-bottom: 30px;
		}

		p{
			color: #7b838a;
		}

		h4 {
      font-weight: 600;
	// 		color: $white;
			margin-bottom: 25px;
			@media (max-width: 1024px) {
				font-size: 20px;
			}
			@media (max-width: 991px) {
				margin-bottom: 15px;
			}
		}
		ul {
			li {
				margin-bottom: 10px;
				a {
					@include transition();
					color: $link-color;
					&:hover {
						color: $primary-color;
					}
				}
			}
		}
		.form-wrap {
      margin-top: 25px;
      
      .info{
        color: $primary-color;
      }
		}
		input {
			height: 45px;
			border: 1px solid #eeeeee;
			width: 67% !important;
			font-weight: 400;
			background: transparent;
			color: $link-color;
			padding-left: 20px;
			border-radius: 0;
			font-size: 14px;
			padding: 10px;
			&:focus {
				outline: none;
				box-shadow: none;
			}
			@include placeholder {
				font-size: 14px;
				padding-left: 10px;
				color: $link-color;
			}
		}
		.click-btn {
			color: $title-color;
			border-radius: 0;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			padding: 6px 18px;
			border: 0;
			background: $primary-color;
			color: $white;
			position: relative;
			left: 0;
			text-transform: capitalize;

			i,span{
				font-size: 14px;
				font-weight: 500;
			}

			&:focus {
				outline: none;
				box-shadow: none;
      }
      
    //   @media(max-width: 375px){
    //     margin-top: 10px;
    //   }

			// @media(min-width: 400px){
			// 	left: -50px;
			// }
		}
	}
	.footer-bottom {
		margin-top: 70px;
		@media (max-width: 991px) {
			margin-top: 20px;
		}
		p {
			color: $link-color;

			a{
				color: $primary-color;
			}
		}
		// span {
		// 	font-weight: 700;
		// }
		.footer-social {
			text-align: center;
			@media (max-width: 991px) {
				text-align: left;
				margin-top: 30px;
			}
			a {
				background: #2f2d4e;
				margin-left: 3px;
				width: 32px;
				color: $white;
				display: inline-grid;
				text-align: center;
				height: 32px;
				align-content: center;
				@include transition();
				&:hover {
					// @extend .gradient-bg;
          background: $primary-color;
          color: $white;
				}

				i,span{
					font-size: 14px;
				}
			}
		}
	}
}
//--------- end footer Area -------------//
