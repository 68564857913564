.sandwichAnimation{
    position: absolute;
    animation: enter 0.5s ease-out;
    margin-left: 310px;
}

@keyframes enter{
    0%{ opacity: 0; margin-top: -300px; }
    100%{ opacity: 1; margin-top: -10px}
}

.ArmEnter{
    position: relative;
    animation: armEnter 0.5s ease-in-out;
}
@keyframes armEnter{
    0%{margin-left: -300px; }
    100%{margin-top: 0px}
}