@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600");
@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:400,600,700");

body {
    color: #888;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.8
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

a:hover {
    text-decoration: none
}

button {
    cursor: pointer
}

button:focus {
    outline: 0 !important;
    box-shadow: none !important
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Josefin Sans", sans-serif;
    color: #2f2d4e;
    line-height: 1.2
}

h1,
.h1 {
    font-size: 70px
}

h2,
.h2 {
    font-weight: 600;
    font-size: 26px
}

@media (min-width: 1200px) {

    h2,
    .h2 {
        font-size: 50px
    }
}

h3,
.h3 {
    font-size: 24px;
    font-weight: 600
}

@media (max-width: 1000px) {

    h3,
    .h3 {
        font-size: 19px
    }
}

h4,
.h4 {
    font-size: 24px;
    font-weight: 600
}

@media (max-width: 450px) {

    h4,
    .h4 {
        font-size: 19px
    }
}

.primary-text {
    color: #e22104
}

.mb-75px {
    margin-bottom: 35px
}

@media (min-width: 992px) {
    .mb-75px {
        margin-bottom: 75px
    }
}

.bg-lightGray {
    /* background: #FFF8F7 */
}

.section-margin {
    margin: 65px 0
}

@media (min-width: 992px) {
    .section-margin {
        margin: 130px 0
    }
}

@media (min-width: 1200px) {
    .pb-xl-70 {
        padding-bottom: 70px
    }
}

@media (min-width: 992px) {
    .mb-lg-100 {
        margin-bottom: 100px
    }
}

.section-padding {
    padding: 65px 0
}

@media (min-width: 992px) {
    .section-padding {
        padding: 130px 0
    }
}

.header_area {
    transition: background 0.4s, all 0.3s linear
}

.header_area .navbar-right {
    min-width: 200px;
    text-align: right
}

@media (max-width: 991px) {
    .header_area .navbar-right {
        margin: 20px 0
    }
}

.header_area .navbar-brand {
    margin-right: 30px;
    padding: 0
}

.header_area .navbar-social {
    min-width: 95px;
    float: right
}

.header_area .navbar-social li {
    margin-right: 12px
}

.header_area .navbar-social li i,
.header_area .navbar-social li span {
    font-size: 13px
}

.header_area .navbar-social li:last-child {
    margin-right: 0
}

.header_area .navbar-social li a:hover i,
.header_area .navbar-social li span {
    color: #e22104
}

.header_area .menu_nav {
    width: 100%
}

.header_area .navbar {
    background: transparent;
    border: 0px;
    border-radius: 0px;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0
}

.header_area .navbar .nav-right {
    min-width: 200px
}

.header_area .navbar .nav .nav-item {
    margin-right: 35px
}

.header_area .navbar .nav .nav-item .nav-link {
    font-family: "Open Sans", sans-serif;
    color: #2f2d4e;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    padding: 33px 0;
    display: inline-block
}

.header_area .navbar .nav .nav-item .nav-link:after {
    display: none
}

.header_area .navbar .nav .nav-item:hover .nav-link,
.header_area .navbar .nav .nav-item.active .nav-link {
    color: #e22104;
    opacity: 1
}

.header_area .navbar .nav .nav-item.submenu {
    position: relative
}

.header_area .navbar .nav .nav-item.submenu ul {
    border: none;
    padding: 0px;
    border-radius: 0px;
    box-shadow: none;
    margin: 0px;
    background: #fff
}

@media (min-width: 992px) {
    .header_area .navbar .nav .nav-item.submenu ul {
        position: absolute;
        top: 120%;
        left: 0px;
        min-width: 200px;
        text-align: left;
        opacity: 0;
        transition: all 300ms ease-in;
        visibility: hidden;
        display: block;
        border: none;
        padding: 0px;
        border-radius: 0px;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.4)
    }
}

.header_area .navbar .nav .nav-item.submenu ul:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #eeeeee transparent transparent transparent;
    position: absolute;
    right: 24px;
    top: 45px;
    z-index: 3;
    opacity: 0;
    transition: all 400ms linear
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item {
    display: block;
    float: none;
    margin-right: 0px;
    border-bottom: 1px solid #ededed;
    margin-left: 0px;
    transition: all 0.4s linear
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
    line-height: 45px;
    color: #e22104 !important;
    padding: 0px 30px;
    transition: all 150ms linear;
    display: block;
    margin-right: 0px
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:last-child {
    border-bottom: none
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:hover .nav-link {
    background-color: #e22104;
    color: #fff !important
}

@media (min-width: 992px) {
    .header_area .navbar .nav .nav-item.submenu:hover ul {
        visibility: visible;
        opacity: 1;
        top: 100%
    }
}

.header_area .navbar .nav .nav-item.submenu:hover ul .nav-item {
    margin-top: 0px
}

.header_area .navbar .nav .nav-item:last-child {
    margin-right: 0px
}

.header_area .navbar .search {
    font-size: 12px;
    line-height: 60px;
    display: inline-block;
    margin-left: 80px
}

.header_area .navbar .search i {
    font-weight: 600
}

.header_area.navbar_fixed .main_menu {
    position: fixed;
    width: 100%;
    top: -70px;
    left: 0;
    right: 0;
    z-index: 99;
    transform: translateY(70px);
    transition: transform 500ms ease, background 500ms ease;
    -webkit-transition: transform 500ms ease, background 500ms ease;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1)
}

.header_area.navbar_fixed .main_menu .navbar {
    background: #7676ff
}

.header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
    line-height: 20px
}

.header_area.navbar_fixed .main_menu .navbar .nav .nav-item:hover .nav-link,
.header_area.navbar_fixed .main_menu .navbar .nav .nav-item.active .nav-link {
    color: #fff
}

@media (min-width: 992px) {
    .header_area.white_menu .navbar .navbar-brand img {
        display: none
    }

    .header_area.white_menu .navbar .navbar-brand img+img {
        display: inline-block
    }
}

@media (max-width: 991px) {
    .header_area.white_menu .navbar .navbar-brand img {
        display: inline-block
    }

    .header_area.white_menu .navbar .navbar-brand img+img {
        display: none
    }
}

.header_area.white_menu .navbar .nav .nav-item .nav-link {
    color: #fff
}

.header_area.white_menu.navbar_fixed .main_menu .navbar .navbar-brand img {
    display: inline-block
}

.header_area.white_menu.navbar_fixed .main_menu .navbar .navbar-brand img+img {
    display: none
}

.header_area.white_menu.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
    line-height: 70px
}

.top_menu {
    background: #ec9d5f
}

.top_menu .float-left a {
    line-height: 40px;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 50px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.top_menu .float-left a:last-child {
    margin-right: 0px
}

.top_menu .float-right .pur_btn {
    color: #fff;
    line-height: 40px;
    display: block;
    padding: 0px 40px;
    font-weight: 500;
    font-size: 12px
}

@media (max-width: 1619px) {
    .header_area .navbar .search {
        margin-left: 40px
    }
}

@media (max-width: 1199px) {
    .header_area .navbar .nav .nav-item {
        margin-right: 28px
    }

    .home_banner_area .banner_inner {
        padding: 100px 0px
    }

    .header_area .navbar .tickets_btn {
        margin-left: 40px
    }

    .home_banner_area .banner_inner .banner_content h2 br {
        display: none;
        font-size: 36px
    }

    .home_banner_area .banner_inner .banner_content h2 {
        font-size: 36px;
        line-height: 45px
    }
}

@media (max-width: 991px) {
    .navbar-toggler {
        border: none;
        border-radius: 0px;
        padding: 0px;
        cursor: pointer;
        margin-top: 27px;
        margin-bottom: 23px
    }

    .header_area .navbar {
        background: #d1cff4
    }

    .header_area .navbar .nav-item.active .nav-link {
        color: #e22104 !important
    }

    .navbar-toggler[aria-expanded="false"] span:nth-child(2) {
        opacity: 1
    }

    .navbar-toggler[aria-expanded="true"] span:nth-child(2) {
        opacity: 0
    }

    .navbar-toggler[aria-expanded="true"] span:first-child {
        transform: rotate(-45deg);
        position: relative;
        top: 7.5px
    }

    .navbar-toggler[aria-expanded="true"] span:last-child {
        transform: rotate(45deg);
        bottom: 6px;
        position: relative
    }

    .navbar-toggler span {
        display: block;
        width: 25px;
        height: 3px;
        background: #e22104;
        margin: auto;
        margin-bottom: 4px;
        transition: all 400ms linear;
        cursor: pointer
    }

    .navbar .container {
        padding-left: 15px;
        padding-right: 15px
    }

    .nav {
        padding: 0px 0px
    }

    .header_top .nav {
        padding: 0px
    }

    .header_area .navbar .nav .nav-item .nav-link {
        padding: 12px 0;
        margin-right: 0px;
        display: block;
        border-bottom: 1px solid #ededed33;
        border-radius: 0px
    }

    .header_area .navbar .search {
        margin-left: 0px
    }

    .header_area .navbar-collapse {
        max-height: 340px;
        overflow-y: scroll
    }

    .header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
        padding: 0px 15px
    }

    .header_area .navbar .nav .nav-item {
        margin-right: 0px
    }

    .home_banner_area .banner_inner .banner_content .banner_map_img {
        display: none
    }

    .header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
        line-height: 40px
    }

    .header_area.white_menu.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
        line-height: 40px
    }

    .header_area.white_menu .navbar .nav .nav-item .nav-link {
        color: #222
    }

    .categories_post img {
        width: 100%
    }

    .categories_post {
        max-width: 360px;
        margin: 0 auto
    }

    .blog_categorie_area .col-lg-4 {
        margin-top: 30px
    }

    .blog_area {
        padding-bottom: 80px
    }

    .single-post-area .blog_right_sidebar {
        margin-top: 30px
    }

    .contact_info {
        margin-bottom: 50px
    }

    .home_banner_area .donation_inner {
        margin-bottom: -30px
    }

    .home_banner_area .dontation_item {
        max-width: 350px;
        margin: auto
    }

    .footer_area .col-sm-6 {
        margin-bottom: 30px
    }

    .footer_area .footer_inner {
        margin-bottom: -30px
    }

    .news_widget {
        padding-left: 0px
    }

    .home_banner_area .banner_inner .home_left_img {
        display: none
    }

    .header_area .navbar .tickets_btn {
        display: none
    }

    .home_banner_area .banner_inner {
        padding: 70px 0px 100px 0px
    }

    .left_side_text {
        margin-bottom: 50px
    }

    .price_item {
        max-width: 360px;
        margin: 0px auto 30px
    }

    .price_inner {
        margin-bottom: -30px
    }
}

@media (max-width: 767px) {
    .home_banner_area {
        min-height: 500px
    }

    .home_banner_area .banner_inner {
        min-height: 500px
    }

    .home_banner_area .banner_inner .banner_content {
        margin-top: 0px
    }

    .blog_banner .banner_inner .blog_b_text h2 {
        font-size: 40px;
        line-height: 50px
    }

    .blog_info.text-right {
        text-align: left !important;
        margin-bottom: 10px
    }

    .home_banner_area .banner_inner .banner_content h3 {
        font-size: 30px
    }

    .home_banner_area .banner_inner .banner_content p br {
        display: none
    }

    .home_banner_area .banner_inner .banner_content h3 span {
        line-height: 45px;
        padding-bottom: 0px;
        padding-top: 0px
    }

    .footer-bottom {
        text-align: center
    }

    .footer-bottom .footer-social {
        text-align: center;
        margin-top: 15px
    }

    .made_life_inner .nav.nav-tabs li {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 15px
    }

    .made_life_inner .nav.nav-tabs {
        margin-bottom: -15px
    }

    .made_life_area.made_white .left_side_text {
        margin-bottom: 0px;
        margin-top: 30px
    }
}

@media (max-width: 575px) {
    .top_menu {
        display: none
    }

    .home_banner_area .banner_inner .banner_content h2 {
        font-size: 28px;
        line-height: 38px
    }

    .home_banner_area {
        min-height: 450px
    }

    .home_banner_area .banner_inner {
        min-height: 450px
    }

    .blog_banner .banner_inner .blog_b_text {
        margin-top: 0px
    }

    .home_banner_area .banner_inner .banner_content img {
        display: none
    }

    .home_banner_area .banner_inner .banner_content h5 {
        margin-top: 0px
    }

    .p_120 {
        padding-top: 70px;
        padding-bottom: 70px
    }

    .main_title h2 {
        font-size: 25px
    }

    .sample-text-area {
        padding: 70px 0 70px 0
    }

    .generic-blockquote {
        padding: 30px 15px 30px 30px
    }

    .blog_details h2 {
        font-size: 20px;
        line-height: 30px
    }

    .blog_banner .banner_inner .blog_b_text h2 {
        font-size: 28px;
        line-height: 38px
    }

    .footer-area {
        padding: 70px 0px
    }

    .pad_top {
        padding-top: 70px
    }

    .pad_btm {
        padding-bottom: 70px
    }
}

@media (max-width: 480px) {
    .header_area .navbar-collapse {
        max-height: 250px
    }

    .home_banner_area .banner_inner .banner_content {
        padding: 30px 15px;
        margin-top: 0px
    }

    .banner_content .white_btn {
        display: block
    }

    .home_banner_area .banner_inner .banner_content h3 {
        font-size: 24px
    }

    .banner_area .banner_inner .banner_content h2 {
        font-size: 32px
    }

    .comments-area .thumb {
        margin-right: 10px
    }

    .testi_item .media .d-flex {
        padding-right: 0px
    }

    .testi_item .media .d-flex img {
        width: auto;
        margin-bottom: 15px
    }

    .testi_item .media {
        display: block
    }

    .banner_btn {
        margin-bottom: 20px
    }
}

.hero-left {
    padding: 10px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px
}

@media (min-width: 576px) {
    .hero-left {
        text-align: left;
        margin-top: 0;
        margin-bottom: 0;
        width: 50%;
        float: left;
        padding-left: 7%;
        padding-top: 10%
    }
}

@media (min-width: 1400px) {
    .hero-left {
        padding-left: 18%
    }
}

@media (min-width: 1840px) {
    .hero-left {
        padding-left: 21%
    }
}

.hero-title {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 25px;
    line-height: 0.929
}

@media (min-width: 840px) {
    .hero-title {
        margin-bottom: 50px
    }
}

@media (min-width: 991px) {
    .hero-title {
        font-size: 50px
    }
}

@media (min-width: 1140px) {
    .hero-title {
        font-size: 70px
    }
}

.hero-info {
    padding-top: 60px
}

@media (min-width: 1420px) {
    .hero-info {
        padding-top: 140px
    }
}

.hero-info li {
    text-align: center;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 10px
}

@media (min-width: 850px) {
    .hero-info li {
        float: left;
        border-right: 1px solid #eeeeee;
        margin-bottom: 0
    }
}

@media (min-width: 1200px) {
    .hero-info li {
        padding-right: 35px;
        padding-left: 35px
    }
}

.hero-info li:first-child {
    padding-left: 0
}

.hero-info li:last-child {
    border-right: 0;
    padding-right: 0
}

.hero-info h4 {
    font-size: 18px;
    margin-top: 15px
}

.hero-info::after {
    content: "";
    display: block;
    clear: both;
    display: table
}

.hero-banner__video {
    /* background: url(/images/Home/play-icon.png) left center no-repeat; */
    margin-left: 15px;
    padding: 12px 5px 12px 45px !important;
    font-size: 14px;
    font-weight: 600;
    color: #2f2d4e
}

@media (min-width: 800px) {
    .hero-banner__video {
        margin-left: 35px
    }
}

.hero-banner__video img {
    margin-right: 7px
}

@media (min-width: 576px) {
    .hero-right {
        margin-top: 10%;
        width: 43%;
        float: left
    }
}

.hero-wrapper {
    position: relative;
}

.hero-wrapper .social-icons {
    position: absolute;
    top: 43%;
    transform: translateY(-50%);
    left: 25px
}

@media (min-width: 1400px) {
    .hero-wrapper .social-icons {
        left: 75px
    }
}

.hero-wrapper .social-icons li {
    margin-bottom: 35px
}

.hero-wrapper .social-icons li:last-child {
    margin-bottom: 0
}

.hero-wrapper .social-icons i,
.hero-wrapper .social-icons span {
    font-size: 20px;
    color: #2f2d4e
}

.hero-wrapper .social-icons a:hover i,
.hero-wrapper .social-icons a:hover span {
    color: #e22104
}

.hero-wrapper::after {
    content: "";
    display: block;
    clear: both;
    display: table
}

.hero-carousel {
    position: relative
}

.hero-carousel .owl-dots {
    position: absolute;
    top: 50%;
    right: 15px;
    margin: 0;
    transform: translateY(-50%)
}

@media (min-width: 576px) {
    .hero-carousel .owl-dots {
        right: -25px
    }
}

@media (min-width: 992px) {
    .hero-carousel .owl-dots {
        right: -65px
    }
}

.hero-carousel .owl-dots .owl-dot {
    display: block
}

.hero-carousel .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 7px 7px;
    background: #d2d2d2
}

.hero-carousel .owl-dots .owl-dot.active span {
    margin-left: 9px;
    width: 5px;
    height: 5px;
    background-color: #e51002;
    position: relative
}

.hero-carousel .owl-dots .owl-dot.active span::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    left: -5px;
    border: 2px solid #e51002
}

.hero-banner-sm .hero-title {
    font-size: 30px;
    margin-bottom: 15px
}

@media (min-width: 1000px) {
    .hero-banner-sm .hero-title {
        font-size: 60px
    }
}

.hero-banner-sm .hero-info {
    padding-top: 20px
}

@media (min-width: 1000px) {
    .hero-banner-sm .hero-info {
        padding-top: 40px
    }
}

.hero-banner-sm .hero-left {
    padding-top: 7%
}

.hero-banner-sm .hero-right .owl-carousel {
    max-height: 600px;
    overflow: hidden;
}

@media (min-width: 575px) {
    .hero-banner-sm .hero-right {
        width: 50%
    }
}

.banner-breadcrumb {
    display: inline-block
}

.banner-breadcrumb .breadcrumb {
    background: transparent;
    padding: 0
}

.banner-breadcrumb .breadcrumb-item {
    padding: .1rem
}

.banner-breadcrumb .breadcrumb-item a {
    text-transform: capitalize;
    color: #fff
}

.banner-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    color: #fff;
    padding-left: .2rem;
    padding-right: .4rem
}

.banner-breadcrumb .breadcrumb-item.active {
    color: #fff
}



.section-intro .intro-title {
    display: inline-block;
    font-size: 24px;
    color: #e22104;
    padding-right: 115px;
    position: relative;
    margin-bottom: 15px
}

.section-intro .intro-title::after {
    content: "";
    display: block;
    height: 1px;
    min-width: 100px;
    background-color: #3a414e;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%)
}

.featured-item {
    background: #FFF8F7;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.featured-item a {
    display: inline-block
}

.featured-item h3 {
    margin-bottom: 0
}

.featured-item p {
    margin-bottom: 10px
}

.featured-item .item-body {
    padding: 30px
}

.featured-item:hover {
    box-shadow: 0px 10px 20px 0px rgba(8, 6, 89, 0.1)
}

.featured-item:hover .item-body {
    background: #fff
}

.price-tag {
    font-family: "Open Sans", sans-serif;
    color: #e22104
}

.rating-star li {
    display: inline-block;
    margin-right: 2px
}

.rating-star li:last-child {
    margin-right: 0
}

.rating-star li i,
.rating-star li span {
    font-size: 11px;
    color: #ffbe0f
}

.featured-carousel {
    position: relative
}

.featured-carousel .owl-stage-outer {
    padding-bottom: 30px
}

.featured-carousel .owl-nav i,
.featured-carousel .owl-nav span {
    font-size: 40px;
    color: #efefef
}

@media (min-width: 1240px) {

    .featured-carousel .owl-nav i,
    .featured-carousel .owl-nav span {
        font-size: 80px
    }
}

.featured-carousel .owl-nav button {
    padding: 0;
    margin: 0;
    opacity: 1 !important
}

.featured-carousel .owl-nav button i,
.featured-carousel .owl-nav button span {
    color: #fea295
}

.featured-carousel .owl-nav button:hover {
    background: transparent !important
}

.featured-carousel .owl-nav button.disabled {
    cursor: not-allowed
}

.featured-carousel .owl-nav button.disabled i,
.featured-carousel .owl-nav button.disabled span {
    color: #efefef
}

.featured-carousel .owl-nav .owl-prev {
    position: absolute;
    top: 24%;
    left: -15px
}

@media (min-width: 1240px) {
    .featured-carousel .owl-nav .owl-prev {
        left: -85px
    }
}

.featured-carousel .owl-nav .owl-next {
    position: absolute;
    top: 24%;
    right: -15px
}

@media (min-width: 1240px) {
    .featured-carousel .owl-nav .owl-next {
        right: -85px
    }
}

.img-styleBox {
    position: relative
}

.img-styleBox .styleBox-img2 {
    position: absolute;
    bottom: -70px;
    right: -15px
}

.offer-card {
    background: #e22104;
    color: #fff;
    text-align: center;
    padding: 45px 20px;
    box-shadow: -13px 2px 20px 0px rgba(8, 6, 89, 0.2)
}

@media (min-width: 992px) {
    .offer-card {
        padding: 80px 20px
    }
}

.offer-card h3 {
    font-size: 20px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 10px
}

@media (min-width: 992px) {
    .offer-card h3 {
        font-size: 40px;
        margin-bottom: 25px
    }
}

.offer-card h2 {
    font-size: 30px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 15px
}

@media (min-width: 992px) {
    .offer-card h2 {
        font-size: 60px;
        margin-bottom: 25px
    }
}

.offer-card .button {
    border: 1px solid #fff
}

@media (min-width: 576px) {
    .offer-card-position {
        position: relative;
        left: -110px;
        top: 70px;
        margin-bottom: 70px
    }
}

.food-card {
    background: #FFF8F7;
    padding: 20px 10px 20px 10px;
    margin-bottom: 20px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

@media (min-width: 600px) {
    .food-card {
        padding: 30px 40px 30px 40px
    }
}

.food-card p {
    margin: 0
}

.food-card .price-tag {
    font-size: 20px
}

.food-card-title {
    position: relative;
    margin-bottom: 5px
}

.food-card-title>* {
    margin: 0
}

.food-card-title::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 40%;
    width: 100%;
    height: 1px;
    background-color: #363636
}

.food-card-title h4 {
    background: #FFF8F7;
    z-index: 1;
    padding-right: 10px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.food-card-title h3 {
    background: #FFF8F7;
    z-index: 1;
    padding-left: 10px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.food-card-title .price-tag {
    z-index: 1
}

.food-card:hover {
    box-shadow: 0px 10px 20px 0px rgba(8, 6, 89, 0.1)
}

.food-card:hover .food-card-title h3,
.food-card:hover .food-card-title h4 {
    background: #fff
}

.cta-area {
    /* background: url(/images/Home/cta-bg.jpg) left center no-repeat; */
    background-size: cover;
    position: relative;
    z-index: 1;
    padding: 80px 0 70px 0
}

@media (min-width: 1200px) {
    .cta-area {
        padding: 120px 0 130px 0
    }
}

.cta-area::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(2, 1, 15, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1
}

.cta-area p {
    font-size: 20px;
    color: #fff
}

.cta-area h2 {
    color: #fff;
    font-size: 30px
}

.cta-area .button {
    margin-top: 35px
}

.chef-card {
    position: relative;
    overflow: hidden
}

.chef-card .chef-footer {
    position: absolute;
    width: 94.5%;
    bottom: 10px;
    left: 50%;
    background: #fff;
    transform: translateX(-50%);
    text-align: center;
    padding: 20px 5px 10px 5px;
    z-index: 10
}

.chef-card h4 {
    margin-bottom: 0
}

.chef-card p {
    color: #777;
    margin-bottom: 0
}

.chef-card .chef-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(2, 1, 15, 0.5);
    transform: translateY(-50%);
    opacity: 0;
    z-index: -1;
    transition: all .6s ease
}

.chef-card .chef-overlay .social-icons {
    position: absolute;
    right: 20px;
    top: 45%;
    transform: translateY(-50%)
}

.chef-card .chef-overlay .social-icons i,
.chef-card .chef-overlay .social-icons span {
    font-size: 13px;
    color: #fff
}

.chef-card .chef-overlay .social-icons li {
    margin-bottom: 4px
}

.chef-card .chef-overlay .social-icons li a:hover i,
.chef-card .chef-overlay .social-icons li a:hover span {
    color: #e22104
}

.chef-card:hover .chef-overlay {
    transform: translateY(0);
    opacity: 1;
    z-index: 1
}

.card-blog {
    background: #FFF8F7;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.blog-body {
    padding: 18px
}

@media (min-width: 1000px) {
    .blog-body {
        padding: 30px
    }
}

.blog-info {
    margin-bottom: 10px
}

.blog-info li {
    font-size: 14px;
    color: #999999;
    float: left
}

.blog-info li a {
    color: #999999
}

.blog-info li::after {
    content: "/";
    padding-right: 4px;
    padding-left: 4px
}

.blog-info li:last-child::after {
    display: none
}

.blog-info::after {
    content: "";
    display: block;
    clear: both;
    display: table
}

.card-blog:hover {
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(8, 6, 89, 0.1)
}

.search-wrapper {
    background:white;
    background-size: cover;
    padding: 30px 10px;
    position: relative;
    z-index: 1
}

.search-wrapper::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1
}

@media (min-width: 600px) {
    .search-wrapper {
        padding: 60px 30px
    }
}

.search-wrapper h3{
    margin-bottom: 25px;
    color: #fff
}


@media (min-width: 600px) {
    .search-wrapper h3 {
        margin-bottom: 40px
    }
}

.form-group-position {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: -22px;
    left: 50%;
    transform: translateX(-50%)
}

.search-form .form-group {
    margin-bottom: 15px
}

.search-form .form-group:last-child {
    margin-bottom: 0
}

.search-form .nice-select {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border: 0;
    border-radius: 0;
    margin-bottom: 20px;
    font-size: 14px;
    color: #999999
}

.search-form .nice-select:after {
    right: 25px
}

.search-form .form-control {
    font-size: 14px;
    color: #999999;
    padding-left: 20px;
    border: 0;
    height: 50px;
    border-radius: 0
}

.search-form .form-control::placeholder {
    color: #999999
}

.search-form .form-control:focus {
    outline: 0;
    box-shadow: none
}

.search-form .input-group-append .input-group-text {
    border: 0;
    padding-right: 20px;
    border-radius: 0;
    background: #fff
}

.search-form .input-group-append i,
.search-form .input-group-append span {
    font-size: 15px;
    color: #bfbfbf
}

.search-form .button {
    padding: 12px 40px;
    box-shadow: -5px 8px 20px 0px rgba(229, 16, 2, 0.15)
}

.search-form::placeholder {
    color: #999999
}

.contact-info {
    margin-bottom: 12px
}

.contact-info__icon {
    margin-right: 20px
}

.contact-info__icon i,
.contact-info__icon span {
    color: #8f9195;
    font-size: 27px
}

.contact-info .media-body h3 {
    font-size: 16px;
    margin-bottom: 0
}

.contact-info .media-body h3 a {
    color: #2f2d4e
}

.contact-info .media-body p {
    color: #999999
}

.contact-title {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 20px
}

.form-contact label {
    color: #e22104;
    font-size: 14px
}

.form-contact .form-group {
    margin-bottom: 30px
}

.form-contact .form-control {
    border: 1px solid #f0e9ff;
    border-radius: 5px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent
}

.form-contact .form-control:focus {
    outline: 0;
    box-shadow: none
}

.form-contact .form-control::placeholder {
    font-weight: 300;
    color: #999999
}

.form-contact textarea {
    border-radius: 12px;
    height: 100% !important
}

.l_blog_item .l_blog_text .date {
    margin-top: 24px;
    margin-bottom: 15px
}

.l_blog_item .l_blog_text .date a {
    font-size: 12px
}

.l_blog_item .l_blog_text h4 {
    font-size: 18px;
    color: #2f2d4e;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 0px;
    padding-bottom: 20px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.l_blog_item .l_blog_text h4:hover {
    color: #e22104
}

.l_blog_item .l_blog_text p {
    margin-bottom: 0px;
    padding-top: 20px
}

.causes_slider .owl-dots {
    text-align: center;
    margin-top: 80px
}

.causes_slider .owl-dots .owl-dot {
    height: 14px;
    width: 14px;
    background: #eeeeee;
    display: inline-block;
    margin-right: 7px
}

.causes_slider .owl-dots .owl-dot:last-child {
    margin-right: 0px
}

.causes_slider .owl-dots .owl-dot.active {
    background: #e22104
}

.causes_item {
    background: #fff
}

.causes_item .causes_img {
    position: relative
}

.causes_item .causes_img .c_parcent {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    height: 3px;
    background: rgba(255, 255, 255, 0.5)
}

.causes_item .causes_img .c_parcent span {
    width: 70%;
    height: 3px;
    background: #e22104;
    position: absolute;
    left: 0px;
    bottom: 0px
}

.causes_item .causes_img .c_parcent span:before {
    content: "75%";
    position: absolute;
    right: -10px;
    bottom: 0px;
    background: #e22104;
    color: #fff;
    padding: 0px 5px
}

.causes_item .causes_text {
    padding: 30px 35px 40px 30px
}

.causes_item .causes_text h4 {
    color: #2f2d4e;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    cursor: pointer
}

.causes_item .causes_text h4:hover {
    color: #e22104
}

.causes_item .causes_text p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 0px
}

.causes_item .causes_bottom a {
    width: 50%;
    border: 1px solid #e22104;
    text-align: center;
    float: left;
    line-height: 50px;
    background: #e22104;
    color: #fff;
    font-size: 14px;
    font-weight: 500
}

.causes_item .causes_bottom a+a {
    border-color: #eeeeee;
    background: #fff;
    font-size: 14px;
    color: #2f2d4e
}

.latest_blog_area {
    background: #f9f9ff
}

.single-recent-blog-post {
    margin-bottom: 30px
}

.single-recent-blog-post .thumb {
    overflow: hidden
}

.single-recent-blog-post .thumb img {
    transition: all 0.7s linear
}

.single-recent-blog-post .details {
    padding-top: 30px
}

.single-recent-blog-post .details .sec_h4 {
    line-height: 24px;
    padding: 10px 0px 13px;
    transition: all 0.3s linear
}

.single-recent-blog-post .date {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400
}

.single-recent-blog-post:hover img {
    transform: scale(1.23) rotate(10deg)
}

.tags .tag_btn {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #eeeeee;
    display: inline-block;
    padding: 1px 18px;
    text-align: center;
    color: #2f2d4e
}

.tags .tag_btn:before {
    background: #e22104
}

.tags .tag_btn+.tag_btn {
    margin-left: 2px
}

.blog_categorie_area {
    padding-top: 30px;
    padding-bottom: 30px
}

@media (min-width: 900px) {
    .blog_categorie_area {
        padding-top: 80px;
        padding-bottom: 80px
    }
}

@media (min-width: 1100px) {
    .blog_categorie_area {
        padding-top: 120px;
        padding-bottom: 120px
    }
}

.categories_post {
    position: relative;
    text-align: center;
    cursor: pointer
}

.categories_post img {
    max-width: 100%
}

.categories_post .categories_details {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: rgba(34, 34, 34, 0.75);
    color: #fff;
    transition: all 0.3s linear;
    display: flex;
    align-items: center;
    justify-content: center
}

.categories_post .categories_details h5 {
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    color: #fff;
    position: relative
}

.categories_post .categories_details p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0px
}

.categories_post .categories_details .border_line {
    margin: 10px 0px;
    background: #fff;
    width: 100%;
    height: 1px
}

.categories_post:hover .categories_details {
    background: rgba(222, 99, 32, 0.85)
}

.blog_item {
    margin-bottom: 50px
}

.blog_details {
    padding: 30px 0 20px 10px;
    box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3)
}

@media (min-width: 768px) {
    .blog_details {
        padding: 60px 30px 35px 35px
    }
}

.blog_details p {
    margin-bottom: 30px
}

.blog_details h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px
}

@media (min-width: 768px) {
    .blog_details h2 {
        font-size: 24px;
        margin-bottom: 15px
    }
}

.blog-info-link li {
    float: left;
    font-size: 14px
}

.blog-info-link li a {
    color: #999999
}

.blog-info-link li i,
.blog-info-link li span {
    font-size: 13px;
    margin-right: 5px
}

.blog-info-link li::after {
    content: "|";
    padding-left: 10px;
    padding-right: 10px
}

.blog-info-link li:last-child::after {
    display: none
}

.blog-info-link::after {
    content: "";
    display: block;
    clear: both;
    display: table
}

.blog_item_img {
    position: relative
}

.blog_item_img .blog_item_date {
    position: absolute;
    bottom: -10px;
    left: 10px;
    display: block;
    color: #fff;
    background: #e22104;
    padding: 8px 15px;
    border-radius: 5px
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date {
        bottom: -20px;
        left: 40px;
        padding: 13px 30px
    }
}

.blog_item_img .blog_item_date h3 {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
    line-height: 1.2
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date h3 {
        font-size: 30px
    }
}

.blog_item_img .blog_item_date p {
    margin-bottom: 0
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date p {
        font-size: 18px
    }
}

.blog_right_sidebar .widget_title {
    font-size: 20px;
    margin-bottom: 40px
}

.blog_right_sidebar .widget_title::after {
    content: "";
    display: block;
    padding-top: 15px;
    border-bottom: 1px solid #f0e9ff
}

.blog_right_sidebar .single_sidebar_widget {
    background: #fbf9ff;
    padding: 30px;
    margin-bottom: 30px
}

.blog_right_sidebar .search_widget .form-control {
    height: 50px;
    border-color: #f0e9ff;
    font-size: 13px;
    color: #999999;
    padding-left: 20px;
    border-radius: 0;
    border-right: 0
}

.blog_right_sidebar .search_widget .form-control::placeholder {
    color: #999999
}

.blog_right_sidebar .search_widget .form-control:focus {
    border-color: #f0e9ff;
    outline: 0;
    box-shadow: none
}

.blog_right_sidebar .search_widget .input-group button {
    background: #fff;
    border-left: 0;
    border: 1px solid #f0e9ff;
    padding: 4px 15px;
    border-left: 0
}

.blog_right_sidebar .search_widget .input-group button i,
.blog_right_sidebar .search_widget .input-group button span {
    font-size: 14px;
    color: #999999
}

.blog_right_sidebar .newsletter_widget .form-control {
    height: 50px;
    border-color: #f0e9ff;
    font-size: 13px;
    color: #999999;
    padding-left: 20px;
    border-radius: 0
}

.blog_right_sidebar .newsletter_widget .form-control::placeholder {
    color: #999999
}

.blog_right_sidebar .newsletter_widget .form-control:focus {
    border-color: #f0e9ff;
    outline: 0;
    box-shadow: none
}

.blog_right_sidebar .newsletter_widget .input-group button {
    background: #fff;
    border-left: 0;
    border: 1px solid #f0e9ff;
    padding: 4px 15px;
    border-left: 0
}

.blog_right_sidebar .newsletter_widget .input-group button i,
.blog_right_sidebar .newsletter_widget .input-group button span {
    font-size: 14px;
    color: #999999
}

.blog_right_sidebar .post_category_widget .cat-list li {
    border-bottom: 1px solid #f0e9ff;
    transition: all 0.3s ease 0s;
    padding-bottom: 12px
}

.blog_right_sidebar .post_category_widget .cat-list li:last-child {
    border-bottom: 0
}

.blog_right_sidebar .post_category_widget .cat-list li a {
    font-size: 14px;
    line-height: 20px;
    color: #888888
}

.blog_right_sidebar .post_category_widget .cat-list li a p {
    margin-bottom: 0px
}

.blog_right_sidebar .post_category_widget .cat-list li+li {
    padding-top: 15px
}

.blog_right_sidebar .post_category_widget .cat-list li:hover a {
    color: #e22104
}

.blog_right_sidebar .popular_post_widget .post_item .media-body {
    justify-content: center;
    align-self: center;
    padding-left: 20px
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
    transition: all 0.3s linear
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3:hover {
    color: #e22104
}

.blog_right_sidebar .popular_post_widget .post_item .media-body p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0px
}

.blog_right_sidebar .popular_post_widget .post_item+.post_item {
    margin-top: 20px
}

.blog_right_sidebar .tag_cloud_widget ul li {
    display: inline-block
}

.blog_right_sidebar .tag_cloud_widget ul li a {
    display: inline-block;
    border: 1px solid #eeeeee;
    background: #fff;
    padding: 4px 20px;
    margin-bottom: 8px;
    margin-right: 5px;
    transition: all 0.3s ease 0s;
    color: #888888;
    font-size: 13px
}

.blog_right_sidebar .tag_cloud_widget ul li a:hover {
    background: #e22104;
    color: #fff
}

.blog_right_sidebar .instagram_feeds .instagram_row {
    display: flex;
    margin-right: -6px;
    margin-left: -6px
}

.blog_right_sidebar .instagram_feeds .instagram_row li {
    width: 33.33%;
    float: left;
    padding-right: 6px;
    padding-left: 6px;
    margin-bottom: 15px
}

.blog_right_sidebar .br {
    width: 100%;
    height: 1px;
    background: #eee;
    margin: 30px 0px
}

.blog-pagination {
    margin-top: 80px
}

.blog-pagination .page-link {
    font-size: 14px;
    position: relative;
    display: block;
    padding: 0;
    text-align: center;
    margin-left: -1px;
    line-height: 45px;
    width: 45px;
    height: 45px;
    border-radius: 0 !important;
    color: #8a8a8a;
    border: 1px solid #f0e9ff;
    margin-right: 10px
}

.blog-pagination .page-link i,
.blog-pagination .page-link span {
    font-size: 13px
}

.blog-pagination .page-link:hover {
    background-color: #fbf9ff
}

.blog-pagination .page-item.active .page-link {
    background-color: #fbf9ff;
    border-color: #f0e9ff;
    color: #888888
}

.blog-pagination .page-item:last-child .page-link {
    margin-right: 0
}

.single-post-area .blog_details {
    box-shadow: none;
    padding: 0
}

.single-post-area .social-links {
    padding-top: 10px
}

.single-post-area .social-links li {
    display: inline-block;
    margin-bottom: 10px
}

.single-post-area .social-links li a {
    color: #cccccc;
    padding: 7px;
    font-size: 14px;
    transition: all 0.2s linear
}

.single-post-area .social-links li a:hover {
    color: #2f2d4e
}

.single-post-area .blog_details {
    padding-top: 26px
}

.single-post-area .blog_details p {
    margin-bottom: 20px
}

.single-post-area .quote-wrapper {
    background: rgba(130, 139, 178, 0.1);
    padding: 15px;
    line-height: 1.733;
    color: #888888;
    font-style: italic;
    margin-top: 25px;
    margin-bottom: 25px
}

@media (min-width: 768px) {
    .single-post-area .quote-wrapper {
        padding: 30px
    }
}

.single-post-area .quotes {
    background: #fff;
    padding: 15px 15px 15px 20px;
    border-left: 2px solid #e22104
}

@media (min-width: 768px) {
    .single-post-area .quotes {
        padding: 25px 25px 25px 30px
    }
}

.single-post-area .arrow {
    position: absolute
}

.single-post-area .arrow i,
.single-post-area .arrow span {
    font-size: 20px;
    font-weight: 600
}

.single-post-area .thumb .overlay-bg {
    background: rgba(0, 0, 0, 0.8)
}

.single-post-area .navigation-top {
    padding-top: 15px;
    border-top: 1px solid #f0e9ff
}

.single-post-area .navigation-top p {
    margin-bottom: 0
}

.single-post-area .navigation-top .like-info {
    font-size: 14px
}

.single-post-area .navigation-top .like-info i,
.single-post-area .navigation-top .like-info span {
    font-size: 16px;
    margin-right: 5px
}

.single-post-area .navigation-top .comment-count {
    font-size: 14px
}

.single-post-area .navigation-top .comment-count i,
.single-post-area .navigation-top .comment-count span {
    font-size: 16px;
    margin-right: 5px
}

.single-post-area .navigation-top .social-icons li {
    display: inline-block;
    margin-right: 15px
}

.single-post-area .navigation-top .social-icons li:last-child {
    margin: 0
}

.single-post-area .navigation-top .social-icons li i,
.single-post-area .navigation-top .social-icons li span {
    font-size: 14px;
    color: #999999
}

.single-post-area .navigation-top .social-icons li:hover i,
.single-post-area .navigation-top .social-icons li:hover span {
    color: #e22104
}

.single-post-area .blog-author {
    padding: 40px 30px;
    background: #fbf9ff;
    margin-top: 50px
}

@media (max-width: 600px) {
    .single-post-area .blog-author {
        padding: 20px 8px
    }
}

.single-post-area .blog-author img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 30px
}

@media (max-width: 600px) {
    .single-post-area .blog-author img {
        margin-right: 15px;
        width: 45px;
        height: 45px
    }
}

.single-post-area .blog-author a {
    display: inline-block
}

.single-post-area .blog-author p {
    margin-bottom: 0
}

.single-post-area .blog-author h4 {
    font-size: 16px
}

.single-post-area .navigation-area {
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
    margin-top: 55px
}

.single-post-area .navigation-area p {
    margin-bottom: 0px
}

.single-post-area .navigation-area h4 {
    font-size: 18px;
    line-height: 25px;
    color: #2f2d4e
}

.single-post-area .navigation-area .nav-left {
    text-align: left
}

.single-post-area .navigation-area .nav-left .thumb {
    margin-right: 20px;
    background: #000
}

.single-post-area .navigation-area .nav-left .thumb img {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.single-post-area .navigation-area .nav-left i,
.single-post-area .navigation-area .nav-left span {
    margin-left: 20px;
    opacity: 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.single-post-area .navigation-area .nav-left:hover i,
.single-post-area .navigation-area .nav-left:hover span {
    opacity: 1
}

.single-post-area .navigation-area .nav-left:hover .thumb img {
    opacity: .5
}

@media (max-width: 767px) {
    .single-post-area .navigation-area .nav-left {
        margin-bottom: 30px
    }
}

.single-post-area .navigation-area .nav-right {
    text-align: right
}

.single-post-area .navigation-area .nav-right .thumb {
    margin-left: 20px;
    background: #000
}

.single-post-area .navigation-area .nav-right .thumb img {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.single-post-area .navigation-area .nav-right i,
.single-post-area .navigation-area .nav-right span {
    margin-right: 20px;
    opacity: 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.single-post-area .navigation-area .nav-right:hover i,
.single-post-area .navigation-area .nav-right:hover span {
    opacity: 1
}

.single-post-area .navigation-area .nav-right:hover .thumb img {
    opacity: .5
}

@media (max-width: 991px) {
    .single-post-area .sidebar-widgets {
        padding-bottom: 0px
    }
}

.comments-area {
    background: transparent;
    border-top: 1px solid #eee;
    padding: 45px 0;
    margin-top: 50px
}

@media (max-width: 414px) {
    .comments-area {
        padding: 50px 8px
    }
}

.comments-area h4 {
    margin-bottom: 35px;
    color: #2f2d4e;
    font-size: 18px
}

.comments-area h5 {
    font-size: 16px;
    margin-bottom: 0px
}

.comments-area a {
    color: #2f2d4e
}

.comments-area .comment-list {
    padding-bottom: 48px
}

.comments-area .comment-list:last-child {
    padding-bottom: 0px
}

.comments-area .comment-list.left-padding {
    padding-left: 25px
}

@media (max-width: 413px) {
    .comments-area .comment-list .single-comment h5 {
        font-size: 12px
    }

    .comments-area .comment-list .single-comment .date {
        font-size: 11px
    }

    .comments-area .comment-list .single-comment .comment {
        font-size: 10px
    }
}

.comments-area .thumb {
    margin-right: 20px
}

.comments-area .thumb img {
    width: 70px;
    height: 70px;
    border-radius: 50%
}

.comments-area .date {
    font-size: 14px;
    color: #cccccc;
    margin-bottom: 0;
    margin-left: 20px
}

.comments-area .comment {
    margin-bottom: 10px;
    color: #777777
}

.comments-area .btn-reply {
    background-color: transparent;
    color: #888888;
    padding: 5px 18px;
    font-size: 14px;
    display: block;
    font-weight: 400;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.comments-area .btn-reply:hover {
    color: #e22104
}

.comment-form {
    border-top: 1px solid #eee;
    padding-top: 45px;
    margin-top: 50px;
    margin-bottom: 20px
}

.comment-form .form-group {
    margin-bottom: 30px
}

.comment-form h4 {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 22px;
    color: #2f2d4e
}

.comment-form .name {
    padding-left: 0px
}

@media (max-width: 767px) {
    .comment-form .name {
        padding-right: 0px;
        margin-bottom: 1rem
    }
}

.comment-form .email {
    padding-right: 0px
}

@media (max-width: 991px) {
    .comment-form .email {
        padding-left: 0px
    }
}

.comment-form .form-control {
    border: 1px solid #f0e9ff;
    border-radius: 5px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent
}

.comment-form .form-control:focus {
    outline: 0;
    box-shadow: none
}

.comment-form .form-control::placeholder {
    font-weight: 300;
    color: #999999
}

.comment-form .form-control::placeholder {
    color: #777777
}

.comment-form textarea {
    padding-top: 18px;
    border-radius: 12px;
    height: 100% !important
}

.comment-form ::-webkit-input-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form ::-moz-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form :-ms-input-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form :-moz-placeholder {
    font-size: 13px;
    color: #777
}

.button {
    display: inline-block;
    background-color: #e22104;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 30px;
    padding: 9px 25px;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

@media (min-width: 600px) {
    .button {
        padding: 12px 45px
    }
}

.button:hover {
    background-color: red;
    color: #fff
}

.button-header {
    padding: 7px 26px;
    border-radius: 0
}

.button-hero {
    align-self: center
}

@media (max-width: 845px) {
    .button-hero {
        padding: 8px 19px
    }
}

.button-shadow {
    box-shadow: -5px 8px 20px 0px rgba(229, 16, 2, 0.15)
}

button.button {
    border: 0
}

.button-contactForm {
    border-radius: 5px
}

.footer-area {
    padding: 120px 0px 120px;
    background: #FFF8F7
}

@media (max-width: 991px) {
    .footer-area {
        padding: 60px 0px
    }
}

@media (max-width: 991px) {
    .footer-area .single-footer-widget {
        margin-bottom: 30px
    }
}

.footer-area .single-footer-widget p {
    color: #7b838a
}

.footer-area .single-footer-widget h4 {
    font-weight: 600;
    margin-bottom: 25px
}

@media (max-width: 1024px) {
    .footer-area .single-footer-widget h4 {
        font-size: 20px
    }
}

@media (max-width: 991px) {
    .footer-area .single-footer-widget h4 {
        margin-bottom: 15px
    }
}

.footer-area .single-footer-widget ul li {
    margin-bottom: 10px
}

.footer-area .single-footer-widget ul li a {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    color: #777
}

.footer-area .single-footer-widget ul li a:hover {
    color: #e22104
}

.footer-area .single-footer-widget .form-wrap {
    margin-top: 25px
}

.footer-area .single-footer-widget .form-wrap .info {
    color: #e22104
}

.footer-area .single-footer-widget input {
    height: 45px;
    border: 1px solid #eeeeee;
    width: 67% !important;
    font-weight: 400;
    background: transparent;
    color: #777;
    padding-left: 20px;
    border-radius: 0;
    font-size: 14px;
    padding: 10px
}

.footer-area .single-footer-widget input:focus {
    outline: none;
    box-shadow: none
}

.footer-area .single-footer-widget input.placeholder {
    font-size: 14px;
    padding-left: 10px;
    color: #777
}

.footer-area .single-footer-widget input:-moz-placeholder {
    font-size: 14px;
    padding-left: 10px;
    color: #777
}

.footer-area .single-footer-widget input::-moz-placeholder {
    font-size: 14px;
    padding-left: 10px;
    color: #777
}

.footer-area .single-footer-widget input::-webkit-input-placeholder {
    font-size: 14px;
    padding-left: 10px;
    color: #777
}

.footer-area .single-footer-widget .click-btn {
    color: #2f2d4e;
    border-radius: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 6px 18px;
    border: 0;
    background: #e22104;
    color: #fff;
    position: relative;
    left: 0;
    text-transform: capitalize
}

.footer-area .single-footer-widget .click-btn i,
.footer-area .single-footer-widget .click-btn span {
    font-size: 14px;
    font-weight: 500
}

.footer-area .single-footer-widget .click-btn:focus {
    outline: none;
    box-shadow: none
}

.footer-area .footer-bottom {
    margin-top: 70px
}

@media (max-width: 991px) {
    .footer-area .footer-bottom {
        margin-top: 20px
    }
}

.footer-area .footer-bottom p {
    color: #777
}

.footer-area .footer-bottom p a {
    color: #e22104
}

.footer-area .footer-bottom .footer-social {
    text-align: center
}

@media (max-width: 991px) {
    .footer-area .footer-bottom .footer-social {
        text-align: left;
        margin-top: 30px
    }
}

.footer-area .footer-bottom .footer-social a {
    background: #2f2d4e;
    margin-left: 3px;
    width: 32px;
    color: #fff;
    display: inline-grid;
    text-align: center;
    height: 32px;
    align-content: center;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.footer-area .footer-bottom .footer-social a:hover {
    background: #e22104;
    color: #fff
}

.footer-area .footer-bottom .footer-social a i,
.footer-area .footer-bottom .footer-social a span {
    font-size: 14px
}


input[type=text]:hover {
    border: 2px #FF0200 solid;
}

input[type=text]:focus {
    outline: none
}

select {
    width: 100%;
}

.custom-select {
    position: relative;
    font-family: Arial;
    outline: none;
}

.custom-select select {
    display: none;
    /*hide original SELECT element: */
}

.select-selected {
    background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
    color: #ffffff;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
}

/* Style items (options): */
.select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}


.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #FF0200;
    cursor: pointer;
    border-radius: 100%;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #FF0200;
    cursor: pointer;
  }

.Toppings {
    text-align: left;
}
.form-group{
    color:white;
    font-family: "Open Sans", sans-serif;
}
.white{
    color: #fff
}
.link:hover{
    cursor: pointer;
}
.link{
    text-decoration: underline;
}
.menu_item{
    width:99px;
    height:99px;
    transition: ease-in-out 0.25s;
}
.menu{
    max-width: 100%;
}
.food-card{
    margin:10px;
}
img{
    width: 30px;
    height: 30px;
}
#HomeImage{
    width: 30%;
    height: 30%;
    margin: 5% 0px 0px 10%;
}
@media only screen and (max-width: 975px) {
    #HomeImage{
        width: 70%;
        left: 0%;
    }
}
@media only screen and (max-width: 1100px) {
    #HomeImage{
        width: 70%;
        left: 50%;
    }
}