/* header style start
  ============================================================================================ */
  .header_area{
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // z-index: 99;
    // background: #fff;
    transition: background 0.4s, all 0.3s linear;

    .navbar-right{
      min-width: 200px;
      text-align: right;

      @media(max-width: 991px){
        margin: 20px 0;
      }
    }

    .navbar-brand{
      margin-right: 30px;
      padding: 0;
    }
    .navbar-social{
      min-width: 95px;
      float: right;

      li{
        margin-right: 12px;

        i,span{
          font-size: 13px;
          // color: $black;
        }

        &:last-child{
          margin-right: 0;
        }

        a:hover i,span{
          color: $primary-color;
        }
      }
    }
.menu_nav{
  width: 100%;
}
  .navbar{
      background: transparent;
      // padding: 5px 0;
      border: 0px;
      border-radius: 0px;
  width: 100%;
      padding-top: 0;
      padding-bottom: 0;
      // @media(min-width: 991px){
      //   padding: 26px 0;
      // }
      .nav-right{
        min-width: 200px;

        a{
          // text-transform: uppercase;
          // font-family: $title-font;
          // font-size: 15px;
          // font-weight: 500;
          // color: $primary-color;
        }
      }
      .nav{
          .nav-item{
            margin-right: 35px;
              .nav-link{
                  // font: 500 12px/100px $rob;
                  font-family: $text-font;
                  color: $title-color;
                  text-transform: capitalize;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 33px 0;                  
                  // color: #fff;
                  // padding: 30px 0;
                  display: inline-block;
                  &:after{
                      display: none;
                  }
              }
               &:hover, &.active{
                   .nav-link{
                       color: $primary-color;
                       opacity: 1;
                   }
               }
              &.submenu{
                  position: relative;
                  ul{
                      border: none;
                      padding: 0px;
                      border-radius: 0px;
                      box-shadow: none;
                      margin: 0px;
                      background: #fff;
                      @media (min-width: 992px){
                          position: absolute;
                          top: 120%;
                          left: 0px;
                          min-width: 200px;
                          text-align: left;
                          opacity: 0;
                          transition: all 300ms ease-in;
                          visibility: hidden;
                          display: block;
                          border: none;
                          padding: 0px;
                          border-radius: 0px;
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.4);
                      }
                      &:before{
                          content: "";
                          width: 0;
                          height: 0;
                          border-style: solid;
                          border-width: 10px 10px 0 10px;
                          border-color: #eeeeee transparent transparent transparent;
                          position: absolute;
                          right: 24px;
                          top: 45px;
                          z-index: 3;
                          opacity: 0;
                          transition: all 400ms linear;
                      }
                      .nav-item{
                          display: block;
                          float: none;
                          margin-right: 0px;
                          border-bottom: 1px solid #ededed;
                          margin-left: 0px;
                          transition: all 0.4s linear;
                          .nav-link{
                              line-height: 45px;
                              // color: $dip;
                              color: $primary-color !important;
                              padding: 0px 30px;
                              transition: all 150ms linear;
                              display: block;
                              margin-right: 0px;
                          }
                          &:last-child{
                              border-bottom: none;
                          }
                          &:hover{
                              .nav-link{
                                background-color: $primary-color;
                                  color: #fff !important;
                              }
                          }    
                      }
                  }
                  &:hover{
                      ul{
                          @media (min-width: 992px){
                              visibility: visible;
                              opacity: 1;
                              top: 100%;
                          }
                          .nav-item{
                              margin-top: 0px;
                          }
                      }
                  }
              }
      &:last-child{
        margin-right: 0px;
      }
          }
      }
      .search{
          font-size: 12px;
          line-height: 60px;
          display: inline-block;
          // color: $dip;
          margin-left: 80px;
          i{
              font-weight: 600;
          }
      }
  }
&.navbar_fixed{
  .main_menu{
    position: fixed;
    width: 100%;
    top: -70px;
    left: 0;
    right: 0;
    // background: $primary-color2;
    z-index: 99;
    // background: $baseColor;
    transform: translateY(70px);
    transition: transform 500ms ease, background 500ms ease;
    -webkit-transition: transform 500ms ease, background 500ms ease;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
    .navbar{
      background: #7676ff;
      .nav{
        .nav-item{
          .nav-link{
            line-height: 20px;
            
          }

          &:hover, &.active{
            .nav-link{
                color: $white;
            }
        }
        }
      }
    }
  }
}
&.white_menu{
  .navbar{
    .navbar-brand{
      @media(min-width: 992px){
        img{
          display: none;
          & + img{
            display: inline-block;
          }
        }
      }
      @media(max-width: 991px){
        img{
          display: inline-block;
          & + img{
            display: none;
          }
        }
      }
    }
    .nav{
      .nav-item{
        .nav-link{
          color: #fff;
        }
      }
    }
  }
  &.navbar_fixed{
    .main_menu{
      .navbar{
        .navbar-brand{
          img{
            display: inline-block;
            & + img{
              display: none;
            }
          }
        }
        .nav{
          .nav-item{
            .nav-link{
              line-height: 70px;
              // color: $dip;
            }
          }
        }
      }
    }
  }
}
}

.top_menu{
background: #ec9d5f;
.float-left{
  a{
    line-height: 40px;
    display: inline-block;
    color: #fff;
    // font-family: $rob; 
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 50px;
    @include transition;
    &:last-child{
      margin-right: 0px;
    }
    &:hover{
      // color: $baseColor;
    }
  }
}
.float-right{
  .pur_btn{
    // background: $baseColor;
    color: #fff;
    // font-family: $rob;
    line-height: 40px;
    display: block;
    padding: 0px 40px;
    font-weight: 500;
    font-size: 12px;
  }
}
}

/* header style end
	============================================================================================ */